















































































import { defineComponent } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { formatAmount } from '../helpers'

export default defineComponent({
  name: 'fundingBreakdown',
  props: {
    initialContribution: Number,
    additionalContribution: Number,
    earnings: Number,
    grants: Number,
    balance: Number
  },
  setup() {
    const { useState: useImpactState } = createNamespacedHelpers('impact')
    const { regularContribution } = useImpactState(['regularContribution'])
    return {
      regularContribution,
      // methods
      formatAmount
    }
  }
})
