




















import { defineComponent, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import AdjusterSlider from './AdjusterSlider.vue'

export default defineComponent({
  name: 'AdjusterIssuesDropdown',
  components: {
    AdjusterSlider
  },
  setup() {
    const { useMutations: useImpactMutations } = createNamespacedHelpers('impact')
    const { setContributionIntervals } = useImpactMutations(['setContributionIntervals'])

    const dropdownFrequency = ['Annually', 'Monthly', 'Semi-monthly (2x a month)', 'Bi-weekly (every 2 weeks)']
    const regularContributionFrequencySelected = ref('Annually')

    const setIntervals = () => {
      setContributionIntervals(regularContributionFrequencySelected.value)
    }

    return {
      dropdownFrequency,
      regularContributionFrequencySelected,
      // methods
      setIntervals
    }
  }
})
