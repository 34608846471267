














































import { defineComponent, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { formatAmount } from '../helpers'
import ConnectDialog from './ConnectDialog.vue'

export default defineComponent({
  name: 'AdjusterSlider',
  components: {
    ConnectDialog
  },
  props: {
    amountMin: {
      type: Number,
      required: true,
      default: 0
    },
    amountMax: {
      type: Number,
      required: true,
      default: 50000
    },
    stepSize: {
      type: Number,
      required: true,
      default: 1
    },
    amountDefault: {
      type: Number,
      required: true,
      default: 0
    },
    whichComponent: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { useMutations: useConnectMutations } = createNamespacedHelpers('connect')
    const { useMutations: useImpactMutations } = createNamespacedHelpers('impact')
    const { setBaseAmount, setAnnualAmount } = useImpactMutations(['setBaseAmount', 'setAnnualAmount'])
    const { resetAllValues, setSendInfo } = useConnectMutations(['resetAllValues', 'setSendInfo'])

    const amount = ref(props.amountDefault)
    const displayConnectMessage = ref(false)
    const valid = ref(true)
    const showConnectDialog = ref(false)

    const setDisplayConnectMessage = () => {
      if (amount.value === props.amountMax) {
        displayConnectMessage.value = true
      } else {
        displayConnectMessage.value = false
      }
    }

    const sendAmount = () => {
      setDisplayConnectMessage()
      if (props.whichComponent === 'regular') {
        setAnnualAmount(amount.value)
      } else if (props.whichComponent === 'initial') {
        if (valid.value) {
          setBaseAmount(amount.value)
        }
      }
    }

    const resetConnectDisplay = () => {
      showConnectDialog.value = false
      resetAllValues()
    }

    // todo: What does this do and what is datalayer
    // const setDataLayer = () => {
    //   dataLayer.push({
    //     event: 'exploreMyImpact',
    //     ecommerce: {
    //       checkout: {
    //         actionField: { step: 5, option: this.$store.state.impact.cause }
    //       }
    //     }
    //   })
    // }

    return {
      amount,
      displayConnectMessage,
      showConnectDialog,
      valid,
      // methods
      formatAmount,
      resetConnectDisplay,
      sendAmount,
      setSendInfo
    }
  }
})
