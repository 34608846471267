var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"grant-container",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"my-0",class:{
        'd-flex flex-column': _vm.$vuetify.breakpoint.mdAndDown
      }},[_c('v-col',{staticClass:"ma-0 pa-2",attrs:{"xs":"12","lg":"7","fullscreen":_vm.$vuetify.breakpoint.xs}},[(_vm.year > 1)?_c('v-card-title',{staticClass:"font-weight-bold pb-0",staticStyle:{"word-break":"break-word"}},[_vm._v("Your Impact After "+_vm._s(_vm.year)+" Years")]):_c('v-card-title',{staticClass:"font-weight-bold pb-0",staticStyle:{"word-break":"break-word"}},[_vm._v("Your Impact After "+_vm._s(_vm.year)+" Year")]),_c('v-row',{staticClass:"my-0",class:{
            'd-flex flex-column': _vm.$vuetify.breakpoint.xs
          }},[_c('v-col',{staticClass:"pt-0",attrs:{"xs":"5","sm":"5"}},[_c('v-card-text',{staticClass:"text-left",class:{
                'pb-0': _vm.$vuetify.breakpoint.xs
              }},[_c('span',{staticClass:"font-weight-bold",style:(("font-size:1.3rem; color:" + (_vm.data[_vm.exampleSelected].backgroundColor)))},[_vm._v(_vm._s(_vm.formatNumber(_vm.unitsGranted)))]),_c('br'),(_vm.unitsGranted > 1)?_c('span',[_vm._v(" "+_vm._s(_vm.data[_vm.exampleSelected].unitMeasure)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.data[_vm.exampleSelected].unitMeasureSingle)+" ")])])],1),_c('v-col',{staticClass:"pt-0",attrs:{"xs":"6","sm":"6"}},[(_vm.year > 1)?_c('v-card-text',{staticClass:"text-left",class:{
                'pb-0': _vm.$vuetify.breakpoint.xs
              }},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"1.3rem"}},[_vm._v(_vm._s(_vm.formatAmount(_vm.grants)))]),_c('br'),_vm._v(" Total granted over "+_vm._s(_vm.year)+" years ")]):_c('v-card-text',{staticClass:"text-left",class:{
                'pb-0': _vm.$vuetify.breakpoint.xs
              }},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"1.3rem"}},[_vm._v(" "+_vm._s(_vm.formatAmount(_vm.grants))+" ")]),_c('br'),_vm._v(" Total granted over "+_vm._s(_vm.year)+" year ")])],1)],1),_c('v-row',{staticClass:"pa-2 ma-0"},[_c('v-card-text',{staticClass:"ml-2 mr-1 mt-0 mb-0 pa-0"},[_c('span',{staticClass:"ma-0 pa-0",style:(("color:" + (_vm.data[_vm.exampleSelected].backgroundColor) + "; font-size:" + _vm.dotSize + "rem;display: flex;\nflex-wrap: wrap;line-height:" + (_vm.dotSize + 0.2) + "rem;"))},[_vm._v(_vm._s(_vm.howManyDots))])])],1),_c('v-row',{staticClass:"pa-2 ma-0"},[(_vm.data[_vm.exampleSelected].dotRatio > 1)?_c('v-chip',{staticClass:"pl-1 ma-2",attrs:{"label":"","color":"grey lighten-4"}},[_c('v-avatar',{staticClass:"ma-0 pb-1",style:(("font-size:0.8rem;color:" + (_vm.data[_vm.exampleSelected].backgroundColor))),attrs:{"left":""}},[_vm._v("⬤")]),_vm._v(" = "+_vm._s(_vm.data[_vm.exampleSelected].dotRatio)+" "+_vm._s(_vm.data[_vm.exampleSelected].unitMeasure)+" ")],1):_c('v-chip',{staticClass:"pl-1 ma-2",attrs:{"label":"","color":"grey lighten-4"}},[_c('v-avatar',{staticClass:"ma-0 pb-1",style:(("font-size:0.8rem; color:" + (_vm.data[_vm.exampleSelected].backgroundColor))),attrs:{"left":""}},[_vm._v("⬤")]),_vm._v(" = "+_vm._s(_vm.data[_vm.exampleSelected].dotRatio)+" "+_vm._s(_vm.data[_vm.exampleSelected].unitMeasureSingle)+" ")],1)],1),(_vm.shown)?_c('v-row',{staticClass:"my-0",class:{
            'pl-0 ml-2 mr-2': _vm.$vuetify.breakpoint.xs
          }},[_c('v-col',{class:{
              'text-left pl-0 ml-4 mr-2 pt-0 pb-0': _vm.$vuetify.breakpoint.xs,
              'text-left pa-0 pr-9 ml-9': _vm.$vuetify.breakpoint.smAndUp
            },staticStyle:{"border-left":"4px solid #DBE3E8","padding-left":"1rem","padding-right":"1rem"},attrs:{"cols":"12"}},[_c('v-card-text',[_vm._v(" Organization: "),_c('a',{attrs:{"href":_vm.data[_vm.exampleSelected].link,"target":"_blank"}},[_c('span',{staticClass:"blue-link"},[_vm._v(_vm._s(_vm.data[_vm.exampleSelected].organization))])])]),_vm._l((_vm.htmlfyJsonString(_vm.data[_vm.exampleSelected].showMoretext)),function(string){return _c('v-card-text',{key:(string + "-text")},[_vm._v(_vm._s(string))])})],2)],1):_vm._e()],1),_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"border-left":"1px solid #DBE3E8","padding-left":"1rem","padding-right":"1rem"},attrs:{"xs":"12","lg":"5","fullscreen":_vm.$vuetify.breakpoint.xs}},[_c('fundingBreakdown',{attrs:{"additionalContribution":_vm.additionalContributions,"initialContribution":_vm.baseAmount,"earnings":_vm.totalInterest,"grants":_vm.grants,"balance":_vm.fundBalance}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }