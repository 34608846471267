








































import { defineComponent, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import ConnectDialogMain from './ConnectDialogMain.vue'
import ConnectDialogContactUs from './ConnectDialogContactUs.vue'
import ConnectDialogFooter from './ConnectDialogFooter.vue'
import ConnectDialogSuccess from './ConnectDialogSuccess.vue'

export default defineComponent({
  name: 'ConnectDialog',
  props: {
    display: {
      type: Boolean,
      required: true
    }
  },
  components: {
    ConnectDialogMain,
    ConnectDialogContactUs,
    ConnectDialogFooter,
    ConnectDialogSuccess
  },
  setup(props, { emit }) {
    const { useState: useConnectState } = createNamespacedHelpers('connect')
    const { showSuccessSection } = useConnectState(['showSuccessSection'])

    const closeDialog = () => {
      emit('resetConnectDisplay', false)
    }

    return {
      // methods
      closeDialog,
      showSuccessSection
    }
  }
})
