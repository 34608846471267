




























































































































































import { computed, defineComponent, ref } from '@vue/composition-api'
import { formatAmount, formatNumber, htmlfyJsonString } from '../helpers'
import fundingBreakdown from './FundBreakdown.vue'

export default defineComponent({
  name: 'grantingYear',
  props: {
    baseAmount: { type: Number, required: true },
    yearlyRegularAmount: { type: Number, required: true },
    donationFrequency: String,
    year: { type: Number, required: true },
    exampleSelected: { type: Number, required: true },
    data: {
      type: Array,
      required: true
    },
    dotSize: Number
  },
  components: {
    fundingBreakdown
  },
  setup(props) {
    const granted = ref(0)
    const earnings = ref(0)
    const shown = ref(false)

    const additionalContributions = computed(() => {
      var annualAmount = props.yearlyRegularAmount * props.year
      return Math.round(annualAmount)
    })

    const fundBalance = computed(() => {
      return props.baseAmount + additionalContributions.value + totalInterest.value - grants.value
    })

    const howManyDots = computed(() => {
      var stringOfDots = ''
      var numberOfDots = Math.round(unitsGranted.value / (props.data[props.exampleSelected] as any).dotRatio)
      for (var i = 0; i < numberOfDots; i++) {
        stringOfDots += '⬤ '
      }
      return stringOfDots
    })

    const grants = computed(() => {
      var grants = granted.value
      var base = props.baseAmount
      for (var i = 0; i < props.year; i++) {
        grants = grants + base * 0.04
        base = base + base * 0.049 - base * 0.04 + props.yearlyRegularAmount
      }
      return Math.round(grants)
    })

    const totalInterest = computed(() => {
      var earning = earnings.value
      var base = props.baseAmount
      for (var i = 0; i < props.year; i++) {
        earning = earning + base * 0.049
        base = base + base * 0.049 - base * 0.04 + props.yearlyRegularAmount
      }
      return Math.round(earning)
    })

    const unitsGranted = computed(() => {
      return Math.round(grants.value / (props.data[props.exampleSelected] as any).unitCost)
    })

    return {
      additionalContributions,
      fundBalance,
      grants,
      howManyDots,
      shown,
      totalInterest,
      unitsGranted,
      // methods
      formatAmount,
      formatNumber,
      htmlfyJsonString
    }
  }
})
