










import { defineComponent, ref } from '@vue/composition-api'
import AdjusterIssuesDropdown from './AdjusterIssuesDropdown.vue'
import AdjusterInitialInvestment from './AdjusterInitialInvestment.vue'
import AdjusterRegularContributions from './AdjusterRegularContributions.vue'

export default defineComponent({
  name: 'Adjuster',
  components: {
    AdjusterIssuesDropdown,
    AdjusterInitialInvestment,
    AdjusterRegularContributions
  },
  props: {
    whichCause: {
      type: String,
      required: false
    }
  },
  setup() {
    const displayRegContributions = ref(false)

    const resetRegContributions = (value: boolean) => {
      displayRegContributions.value = value
    }

    return {
      displayRegContributions,
      // methods
      resetRegContributions
    }
  }
})
