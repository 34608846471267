















































import { computed, defineComponent, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { formatAmount } from '../helpers'
import ConnectDialogForm from './ConnectDialogForm.vue'

export default defineComponent({
  name: 'ConnectDialogMain',
  components: {
    ConnectDialogForm
  },
  setup() {
    const { useState: useConnectState, useMutations: useConnectMutations } = createNamespacedHelpers('connect')
    const { useState: useImpactState } = createNamespacedHelpers('impact')
    const { cause, baseAmount, annualAmount, contributionInterval } = useImpactState([
      'cause',
      'baseAmount',
      'annualAmount',
      'contributionInterval'
    ])
    const { sendInfo } = useConnectState(['sendInfo'])
    const { setSendInfo } = useConnectMutations(['setSendInfo'])
    const sendInfoRef = ref(sendInfo.value)

    const hasRegularContributions = computed(() => {
      if (annualAmount.value > 0) {
        return true
      } else {
        return false
      }
    })

    return {
      annualAmount,
      baseAmount,
      cause,
      contributionInterval,
      hasRegularContributions,
      sendInfoRef,
      // methods
      formatAmount,
      setSendInfo
    }
  }
})
