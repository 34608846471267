
























































































import { computed, defineComponent, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import Header from '../components/Header.vue'
import grantingYear from '../components/GrantingYear.vue'
import Adjuster from '../components/Adjuster.vue'
import CallToAction from '../components/CallToAction.vue'
import ImpactExample from '../components/ImpactExample.vue'
import issueOneData from '../data/issueOne.json'
import issueTwoData from '../data/issueTwo.json'
import issueThreeData from '../data/issueThree.json'
import issueFourData from '../data/issueFour.json'
import issueFiveData from '../data/issueFive.json'
import issueSixData from '../data/issueSix.json'

export default defineComponent({
  name: 'ImpactPage',
  components: {
    Header,
    grantingYear,
    Adjuster,
    CallToAction,
    ImpactExample
  },
  setup() {
    const { useState: useImpactState } = createNamespacedHelpers('impact')
    const { annualAmount, baseAmount, cause, contributionInterval, example } = useImpactState([
      'annualAmount',
      'baseAmount',
      'cause',
      'contributionInterval',
      'example'
    ])

    const causeJson = ref(issueOneData)
    const showImpact = ref(false)
    const step = ref(2)

    const getYearlyRegularAmount = computed(() => {
      let contributionMultiplier = 1
      let interval = contributionInterval.value
      if (interval === 'Monthly') {
        contributionMultiplier = 12
      } else if (interval === 'Semi-monthly (2x a month)') {
        contributionMultiplier = 24
      } else if (interval === 'Bi-weekly (every 2 weeks)') {
        contributionMultiplier = 26
      } else {
        contributionMultiplier = 1
      }
      let yearlyRegularAmount = annualAmount.value * contributionMultiplier
      return yearlyRegularAmount
    })

    const dotSize = computed(() => {
      let grants = 0
      let base = baseAmount.value
      let yearlyRegularAmount = getYearlyRegularAmount.value
      for (let i = 0; i < 25; i++) {
        grants = grants + base * 0.04
        base = base + base * 0.049 - base * 0.04 + yearlyRegularAmount
      }
      let unitsGranted = Math.round(grants / causeJson.value[example.value].unitCost)
      let numberofDots = Math.round(unitsGranted / causeJson.value[example.value].dotRatio)
      if (numberofDots > 6000) {
        return 0.1
      } else if (numberofDots > 4000) {
        return 0.2
      } else if (numberofDots > 2000) {
        return 0.3
      } else if (numberofDots > 1000) {
        return 0.4
      } else if (numberofDots > 500) {
        return 0.6
      } else {
        return 0.8
      }
    })

    const seeImpact = () => {
      showImpact.value = true
      step.value = 3
    }

    const getFile = () => {
      if (cause.value === issueOneData[0].title) {
        causeJson.value = issueOneData
      } else if (cause.value === issueTwoData[0].title) {
        causeJson.value = issueTwoData
      } else if (cause.value === issueThreeData[0].title) {
        causeJson.value = issueThreeData
      } else if (cause.value === issueFourData[0].title) {
        causeJson.value = issueFourData
      } else if (cause.value === issueFiveData[0].title) {
        causeJson.value = issueFiveData
      } else {
        causeJson.value = issueSixData
      }
    }

    // todo: more datalayer
    // setDataLayer() {
    //   dataLayer.push({
    //     event: 'exploreMyImpact',
    //     ecommerce: {
    //       checkout: {
    //         actionField: { step: 4 }
    //       }
    //     }
    //   })
    // }

    return {
      baseAmount,
      causeJson,
      contributionInterval,
      dotSize,
      example,
      getYearlyRegularAmount,
      headerTitle: 'Your Impact',
      headerTitleXsmall: 'Your Contribution',
      showImpact,
      step,
      years: [1, 5, 25],
      // methods
      getFile,
      seeImpact
    }
  }
})
