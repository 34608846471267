













































































































import { defineComponent, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
// import { mask } from 'vue-the-mask'
import Axios from 'axios'
import ConnectDialogFailure from './ConnectDialogFailure.vue'
// import VueRecaptcha from "vue-recaptcha";
const mask = require('vue-the-mask')

const HUBSPOT_ACCOUNT = process.env.VUE_APP_HUBSPOT_ACCOUNT
const HUBSPOT_FORM_NUMBER = process.env.VUE_APP_HUBSPOT_FORM_NUMBER
// Uncomment for recaptcha
// const RECAPTCHA_PUBLIC_KEY = process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY_V2;

export default defineComponent({
  name: 'ConnectDialogForm',
  components: {
    ConnectDialogFailure
    // Uncomment for recaptcha
    // VueRecaptcha
  },
  directives: {
    mask
  },
  setup() {
    const form = ref<Vue | null>(null)

    const { useState: useConnectState, useMutations: useConnectMutations } = createNamespacedHelpers('connect')
    const { useState: useImpactState } = createNamespacedHelpers('impact')

    const { sendInfo } = useConnectState(['sendInfo'])
    const { cause, baseAmount, annualAmount, contributionInterval } = useImpactState([
      'cause',
      'baseAmount',
      'annualAmount',
      'contributionInterval'
    ])
    const { setShowSuccessSection } = useConnectMutations(['setShowSuccessSection'])

    const firstName = ref('')
    const lastName = ref('')
    const radioGroup = ref(0)
    const phone = ref('')
    const email = ref('')
    const additionalComments = ref('')
    const valid = ref(false)
    const fields = ref('')
    const userResponse = ref('')
    const failure = ref(false)

    const radioGroupLabels = ['Phone', 'Email']
    const mask = '###-###-####'
    // Uncomment this to enable recaptcha
    // const recapKey = RECAPTCHA_PUBLIC_KEY,

    const getRadioLabels = (n: number) => {
      return radioGroupLabels[n - 1]
    }

    const findPreference = () => {
      if (radioGroup.value === 1) {
        return 'Phone'
      } else if (radioGroup.value === 2) {
        return 'E-mail'
      }
    }

    const showSuccessDialog = (show: boolean) => {
      setShowSuccessSection(show)
    }

    const setEmptyPhoneEmailValue = () => {
      if (radioGroup.value === 1) {
        // Communication preference is "phone"
        email.value = ''
      } else if (radioGroup.value === 2) {
        // Communication preference is "e-mail"
        phone.value = ''
      }
    }

    const resetMessageIfNone = () => {
      if (additionalComments.value === undefined) {
        additionalComments.value = ''
      }
    }

    const doHubSpot = () => {
      doHubSpotRoutine()
      // this.setDataLayer()
    }

    const doHubSpotRoutine = () => {
      setEmptyPhoneEmailValue()
      resetMessageIfNone()
      setHubSpotFields()
      handleHubSpotSubmit().then((event: any) => {
        if (event) {
          showSuccessDialog(true)
          failure.value = false
          // form.value?.$data.reset()
        } else {
          failure.value = true
        }
      })
    }

    const setHubSpotFields = () => {
      const hubSpotFields = [
        {
          name: 'firstname',
          value: firstName.value
        },
        {
          name: 'lastname',
          value: lastName.value
        },
        {
          name: 'preference',
          value: findPreference()
        },
        {
          name: 'phone',
          value: phone.value
        },
        { name: 'email', value: email.value },
        {
          name: 'message',
          value: additionalComments.value
        }
      ]
      if (sendInfo) {
        let causeField = {
          name: 'communityissue',
          value: cause.value
        }
        hubSpotFields.push(causeField)
        let baseAmountField = {
          name: 'initialinvestment',
          value: baseAmount.value
        }
        hubSpotFields.push(baseAmountField)
        if (annualAmount.value > 0) {
          let annualAmountField = {
            name: 'regularcontribution',
            value: annualAmount.value
          }
          hubSpotFields.push(annualAmountField)
          let contributionIntervalField = {
            name: 'contributioninterval',
            value: contributionInterval.value
          }
          hubSpotFields.push(contributionIntervalField)
        }
      }
      let plainFields = {
        hubSpotFields,
        skipValidation: true //Skipping Hubspot validation, which has specific/stronger rules for e-mail addresses
      }
      fields.value = JSON.stringify(plainFields)
    }

    const handleHubSpotSubmit = () => {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return Axios.post(
        // kwcf google account,
        `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_ACCOUNT}/${HUBSPOT_FORM_NUMBER}`,
        fields.value,
        axiosConfig
      )
        .then((response) => {
          userResponse.value = response.data.inlineMessage
          return true
        })
        .catch((error) => {
          return false
        })
    }

    // errorCaught() {
    //   this.failure = true;
    // },
    // onCaptchaVerified: function(recaptchaToken) {
    //   if (recaptchaToken) {
    //     this.doHubSpotRoutine();
    //     this.failure = false;
    //   } else {
    //     this.failure = true;
    //   }
    //   this.setDataLayer();
    // },
    // onCaptchaExpired: function() {
    //   this.failure = true;
    // },
    // Remove if using onCaptchaVerified
    // todo: more datalayer
    // setDataLayer() {
    //   dataLayer.push({
    //     event: 'exploreMyImpact',
    //     ecommerce: {
    //       checkout: {
    //         actionField: { step: 6, option: this.$store.state.impact.cause }
    //       }
    //     }
    //   })
    // },

    return {
      additionalComments,
      email,
      failure,
      firstName,
      form,
      lastName,
      mask,
      phone,
      valid,
      radioGroup,
      // methods
      doHubSpot,
      getRadioLabels
    }
  },
  data: function() {
    return {
      nameRules: {
        required: (value: string) => !!value || 'Name is required.'
      },
      radioRules: {
        required: (value: string) => !!value || 'One option is required.'
      },
      emailRules: {
        required: (value: string) => !!value || 'E-mail is required.',
        valid: (value: string) => /.+@.+\..+/.test(value) || 'E-mail must be valid.'
      },
      phoneRules: {
        required: (value: string) => !!value || 'Phone is required.',
        valid: (value: string) => /\d{3}-\d{3}-\d{4}/.test(value) || 'Phone must be of the form ###-###-####.'
      }
    }
  }
})
