var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-0 pl-4 pr-4 pb-4"},[_c('v-card-title',{staticClass:"pt-4 pl-2 pb-3 pr-0 ma-0 font-weight-bold",staticStyle:{"font-size":"16px"}},[_vm._v("How It Works")]),_c('v-row',{staticClass:"pa-0 pl-2 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-card-text',{staticClass:"text-left pa-0 ma-0",class:{
          'text-no-wrap': _vm.$vuetify.breakpoint.mdAndUp
        }},[_vm._v("Initial contribution:")])],1),_c('v-col',{staticClass:"pt-0 pr-4 pb-0 pl-0 ma-0"},[_c('v-card-text',{staticClass:"text-right pa-0 ma-0 font-weight-bold"},[_vm._v(_vm._s(_vm.formatAmount(_vm.initialContribution)))])],1)],1),(_vm.regularContribution)?_c('v-row',{staticClass:"pa-0 pl-2 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-card-text',{staticClass:"text-left pa-0 ma-0",class:{
          'text-no-wrap': _vm.$vuetify.breakpoint.mdAndUp
        }},[_vm._v("Additional contributions:")])],1),_c('v-col',{staticClass:"pt-0 pr-4 pb-0 pl-0 ma-0"},[_c('v-card-text',{staticClass:"text-right pa-0 ma-0 font-weight-bold"},[_vm._v("+ "+_vm._s(_vm.formatAmount(_vm.additionalContribution)))])],1)],1):_vm._e(),_c('v-row',{staticClass:"pa-0 pl-2 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-card-text',{staticClass:"text-left pa-0 ma-0",class:{
          'text-no-wrap': _vm.$vuetify.breakpoint.mdAndUp
        }},[_vm._v("Total earnings:")])],1),_c('v-col',{staticClass:"pt-0 pr-4 pb-0 pl-0 ma-0"},[_c('v-card-text',{staticClass:"text-right pa-0 ma-0 font-weight-bold"},[_vm._v("+ "+_vm._s(_vm.formatAmount(_vm.earnings)))])],1)],1),_c('v-row',{staticClass:"pa-0 pl-2 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-card-text',{staticClass:"text-left pa-0 ma-0",class:{
          'text-no-wrap': _vm.$vuetify.breakpoint.mdAndUp
        }},[_vm._v("Total grants:")])],1),_c('v-col',{staticClass:"pt-0 pr-4 pb-0 pl-0 ma-0"},[_c('v-card-text',{staticClass:"text-right pa-0 ma-0 font-weight-bold"},[_vm._v("- "+_vm._s(_vm.formatAmount(_vm.grants)))])],1)],1),_c('v-divider',{attrs:{"color":"grey"}}),_c('v-row',{staticClass:"pa-0 pl-2 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-card-text',{staticClass:"text-left pa-0 ma-0",class:{
          'text-no-wrap': _vm.$vuetify.breakpoint.mdAndUp
        }},[_vm._v("Fund balance:")])],1),_c('v-col',{staticClass:"pt-0 pr-4 pb-0 pl-0 ma-0"},[_c('v-card-text',{staticClass:"text-right pa-0 ma-0 font-weight-bold"},[_vm._v(_vm._s(_vm.formatAmount(_vm.balance)))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }