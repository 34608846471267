
























































































































import { computed, defineComponent, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { htmlfyJsonString } from '../helpers'
import issueOneData from '../data/issueOne.json'
import issueTwoData from '../data/issueTwo.json'
import issueThreeData from '../data/issueThree.json'
import issueFourData from '../data/issueFour.json'
import issueFiveData from '../data/issueFive.json'
import issueSixData from '../data/issueSix.json'

export default defineComponent({
  name: 'ImpactExample',
  setup() {
    const { useState: useImpactState, useMutations: useImpactMutations } = createNamespacedHelpers('impact')
    const { cause, example } = useImpactState(['cause', 'example'])
    const { setExample } = useImpactMutations(['setExample'])

    const causeJson = ref(issueOneData)
    const exampleSelected = ref(example.value)

    const dropdownExamples = computed(() => {
      let examples = []
      for (let i = 0; i < causeJson.value.length; i++) {
        examples.push({
          text: causeJson.value[i].exampleTitle,
          value: i
        })
      }
      return examples
    })

    const getFile = () => {
      setExample(example.value)
      if (cause.value === issueOneData[0].title) {
        causeJson.value = issueOneData
      } else if (cause.value === issueTwoData[0].title) {
        causeJson.value = issueTwoData
      } else if (cause.value === issueThreeData[0].title) {
        causeJson.value = issueThreeData
      } else if (cause.value === issueFourData[0].title) {
        causeJson.value = issueFourData
      } else if (cause.value === issueFiveData[0].title) {
        causeJson.value = issueFiveData
      } else {
        causeJson.value = issueSixData
      }
    }

    return {
      causeJson,
      dropdownExamples,
      exampleSelected,
      // methods
      getFile,
      htmlfyJsonString,
      setExample
    }
  }
})
