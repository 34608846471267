





















































import { defineComponent, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import AdjusterSlider from './AdjusterSlider.vue'

export default defineComponent({
  name: 'AdjusterInitialInvestment',
  components: {
    AdjusterSlider
  },
  setup(props, { emit }) {
    const { useMutations: useImpactMutations } = createNamespacedHelpers('impact')
    const { setAnnualAmount, setContributionIntervals, setRegularContribution } = useImpactMutations([
      'setAnnualAmount',
      'setContributionIntervals',
      'setRegularContribution'
    ])

    const depressed = ref(true)
    const right = ref(true)
    const outlined = ref(true)
    const selected = ref(null)
    const toggleExclusive = ref(1)
    const isYesClicked = ref(false)

    const sendAmount = (display: boolean) => {
      if (display === true) {
        setAnnualAmount(0)
        setContributionIntervals('Annually')
        setRegularContribution(true)
      } else {
        setAnnualAmount(0)
        setContributionIntervals('Annually')
        setRegularContribution(false)
      }
    }

    const setDisplayRegularContributions = (display: boolean) => {
      emit('resetRegContributions', display)
      if (display === true && isYesClicked.value === false) {
        sendAmount(true)
        isYesClicked.value = true
      } else if (display === false && isYesClicked.value === true) {
        sendAmount(false)
        isYesClicked.value = false
      }
    }

    return {
      depressed,
      right,
      outlined,
      selected,
      toggleExclusive,
      isYesClicked,
      // methods
      setDisplayRegularContributions
    }
  }
})
