














































import { defineComponent, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import ConnectDialog from './ConnectDialog.vue'

export default defineComponent({
  name: 'CallToAction',
  components: {
    ConnectDialog
  },
  setup() {
    const { useMutations: useConnectMutations } = createNamespacedHelpers('connect')
    const { resetAllValues } = useConnectMutations(['resetAllValues'])

    const showConnectDialog = ref(false)

    const resetConnectDisplay = () => {
      showConnectDialog.value = false
      resetAllValues()
    }

    // todo: figure this out
    // setDataLayer() {
    //   dataLayer.push({
    //     event: 'exploreMyImpact',
    //     ecommerce: {
    //       checkout: {
    //         actionField: { step: 5, option: this.$store.state.impact.cause }
    //       }
    //     }
    //   })
    // }

    return {
      showConnectDialog,
      // methods
      resetConnectDisplay
    }
  }
})
