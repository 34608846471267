



















import { computed, defineComponent, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import causesData from '../data/causes.json'

export default defineComponent({
  name: 'AdjusterIssuesDropdown',
  setup() {
    const { useState: useImpactState, useMutations: useImpactMutations } = createNamespacedHelpers('impact')
    const { cause } = useImpactState(['cause'])
    const { setCause, setExample } = useImpactMutations(['setCause', 'setExample'])

    const selectedCause = ref(cause.value)

    const setNewCause = (newCause: string) => {
      setCause(newCause)
      setExample(0)
    }

    const dropdownCauses = computed(() => {
      let dropdownItems = []
      for (let i = 0; i < causesData.length; i++) {
        dropdownItems.push(causesData[i].title)
      }
      return dropdownItems
    })

    return {
      dropdownCauses,
      selectedCause,
      // methods
      setNewCause
    }
  }
})
