var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"grant-container",attrs:{"elevation":"0"}},[_vm._v(" "+_vm._s(_vm.getFile())+" "),_c('v-row',{class:{
        'd-flex flex-column': _vm.$vuetify.breakpoint.xs,
        'ma-0 pa-0': _vm.$vuetify.breakpoint.smAndUp
      }},[_c('v-col',{class:{
          'text-left ma-0 ml-3 pa-4': _vm.$vuetify.breakpoint.xs,
          'text-left ma-0 ml-2 mt-4 pa-4 pb-0': _vm.$vuetify.breakpoint.smAndUp
        },attrs:{"fullscreen":_vm.$vuetify.breakpoint.xs,"sm":"4"}},[_c('p',[_vm._v(" Impact Example ")])]),_c('v-col',{class:{
          'text-left  ma-0 ml-3 pt-0 pb-0 pl-4 pr-9': _vm.$vuetify.breakpoint.xs,
          'text-left mt-3 pb-0': _vm.$vuetify.breakpoint.smAndUp
        },attrs:{"fullscreen":_vm.$vuetify.breakpoint.xs,"sm":"5"}},[_c('v-select',{staticClass:"body-2",attrs:{"items":_vm.dropdownExamples,"solo":"","dense":"","label":"Impact Example","aria-label":_vm.exampleSelected},on:{"change":function($event){return _vm.setExample(_vm.exampleSelected)}},model:{value:(_vm.exampleSelected),callback:function ($$v) {_vm.exampleSelected=$$v},expression:"exampleSelected"}})],1)],1),_c('v-row',{class:{
        'd-flex flex-column': _vm.$vuetify.breakpoint.xs,
        'ma-0 pa-0': _vm.$vuetify.breakpoint.smAndUp
      }},[_c('v-col',{staticClass:"ma-0 pa-2",attrs:{"fullscreen":_vm.$vuetify.breakpoint.xs}},[_c('v-row',{staticClass:"ma-0 pa-0",class:{
            'd-flex flex-column': _vm.$vuetify.breakpoint.xs
          }},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"xs":"12","sm":"2"}},[_c('v-icon',{class:{
                'mt-2 mb-3 ml-6 pa-0 icon': _vm.$vuetify.breakpoint.xs,
                'ma-0 mt-3 ml-4 pa-0 icon': _vm.$vuetify.breakpoint.sm,
                'ma-0 ml-4 pa-0 icon': _vm.$vuetify.breakpoint.mdAndUp
              },style:(("background-color:" + (_vm.causeJson[_vm.exampleSelected].backgroundColor))),attrs:{"max-height":"4.25rem","max-width":"4.25rem","color":"white"}},[_vm._v(_vm._s(_vm.causeJson[_vm.exampleSelected].image))])],1),_c('v-col',{class:{
              'ma-0 pa-0 pr-6': _vm.$vuetify.breakpoint.xs,
              'ma-0 pa-0 pr-4': _vm.$vuetify.breakpoint.sm,
              'ma-0 pa-0 pr-4': _vm.$vuetify.breakpoint.md,
              'pa-0 pr-4': _vm.$vuetify.breakpoint.lgAndUp
            },attrs:{"xs":"12","sm":"10"}},_vm._l((_vm.htmlfyJsonString(_vm.causeJson[_vm.exampleSelected].showMoretext)),function(string){return _c('v-card-text',{key:(string + "-text"),staticClass:"body-2 pa-0 pr-2",class:{
                'pl-6': _vm.$vuetify.breakpoint.xs
              }},[_vm._v(" "+_vm._s(string)+" "),_c('br'),_c('br')])}),1)],1),_c('v-row',{class:{
            'pl-0 ml-2 mr-2': _vm.$vuetify.breakpoint.xs
          }},[_c('v-col',{class:{
              'text-left pl-0 mr-2 pt-0 pb-0': _vm.$vuetify.breakpoint.xs,
              'text-left  pt-0': _vm.$vuetify.breakpoint.smAndUp
            },attrs:{"cols":"12"}},[_c('v-card-text',{staticClass:"body-2 pb-1 pt-0"},[_vm._v(" These impact examples are based on cost estimates provided by "),_c('a',{attrs:{"href":_vm.causeJson[_vm.exampleSelected].link,"target":"_blank"}},[_c('span',{staticClass:"blue-link"},[_vm._v(_vm._s(_vm.causeJson[_vm.exampleSelected].organization)+".")])])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }